import axios from 'axios'

const productoseliminados = {

    PostCrear: (datos) => axios.post(`calificaciones-tipos/crear-tipo`,datos),
    GetProductos: () => axios.get(`productos-eliminados/listar`),
    GetProducto: (id_producto, params) => axios.get(`productos-eliminados/${id_producto}`,{ params }),
    PutEditar: (datos) => axios.post(`calificaciones-tipos/editar-tipo`,datos),
    Delete: (id) => axios.delete(`calificaciones-tipos/${id}/eliminar-tipo`),
    PostActualizarPosicion: (datos) => axios.post('calificaciones-tipos/posicion-tipo', datos),
    PutEstado: (datos) => axios.post(`calificaciones-tipos/estado-tipo`,datos),
}
export default productoseliminados
