import axios from 'axios'

const API_PREFIX = 'faqs-aceptadas'

const ENDPOINTS = {
    getFaqsAceptadas: params => axios(`${API_PREFIX}/lista`, { params }),
    getFaqsAceptadasUser: idUser => axios(`${API_PREFIX}/user/${idUser}`)
};

export default ENDPOINTS
