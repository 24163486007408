<template>
    <section>
        <div class="row mx-0 py-3">
            <div class="col bg-white br-8 py-4">
                <div class="row align-items-center mx-0">
                    <div class="col-auto text-general f-18 f-500">
                        Productos sin confirmar
                    </div>
                    <div class="col-auto ml-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Hace más de una semana
                            <b class="ml-3">
                                {{ masUnaSemana }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Hace una semana
                            <b class="ml-3">
                                {{ haceUnaSemana }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Ayer
                            <b class="ml-3">
                                {{ ayer }}
                            </b>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-fondo text-general br-8 py-3 br-8 px-3">
                            Hoy
                            <b class="ml-3">
                                {{ hoy }}
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12 px-0">
                <tabla-general
                :data="lista"
                :columnas="dataColumns"
                alto="calc(100vh - 394px)"
                :usar-paginacion="false"
                :cargando="loading"
                activar-seleccion-multiple
                activar-seleccion
                @seleccion="data => onSelect(data)"
                @filaFuncion="data => onClick(data)"
                >
                    <template slot="cabecera-izquierda">
                        <div class="col pr-0">
                            <div class="row mx-0 align-items-center py-3">
                                <div class="col-auto text-general f-600 f-17">
                                    Productos sin confirmar precio en compras
                                </div>
                                <div class="col-auto ml-auto px-0">
                                    <el-tooltip placement="bottom" content="Confirmar precio de todos los productos seleccionados" effect="light">
                                        <div class="btn-action br-8 p-1 border cr-pointer" @click="confirmaPrecio()">
                                            <i class="icon-ok-circled-outline text-success f-18" />
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        width="80"
                        class-name="text-center"
                        >
                            <template slot-scope="{row}">
                                <img :src="row.foto" width="42" height="42" class="obj-cover br-8" />
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Producto"
                        class-name="text-general f-16"
                        width="250"
                        >
                            <template slot-scope="scope">
                                <p>
                                    {{ scope.row.nombre }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Costo"
                        class-name="text-general f-16"
                        width="200"
                        >
                            <template slot-scope="scope">
                                {{ separadorNumeroDecimales(scope.row.unidad_costo) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Precio venta"
                        class-name="text-general f-16"
                        width="200"
                        >
                            <template slot-scope="scope">
                                {{ separadorNumeroDecimales(scope.row.unidad_final) }}
                                <el-tooltip v-if="scope.row.promocion != 0" content="Promoción" placement="bottom" effect="light">
                                    <div class="rounded-circle bg-general position-absolute" style="width:10px;height:10px; right:8vh;top:3.2vh;" />
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Unidades"
                        class-name="text-general f-16"
                        width="200"
                        >
                            <template slot-scope="scope">
                                {{ agregarSeparadoresNumero(scope.row.cantidad) }} {{ scope.row.sigla }}
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalConfirmarPrecioTodos" titulo="Confirmar precio de producto" icon="aprobar" tamano="modal-lg" no-aceptar no-cancelar cerrar adicional="Confirmar" @adicional="confirmarMultiple()">
            <p class="text-general f-15 text-center my-3">
                ¿Desea confirmar los precios de todos los productos seleccionados?
            </p>
        </modal>
        <modal-precio-individual ref="modalConfirmarPrecioInd" @saved="listar()" />
    </section>
</template>

<script>
import moment from 'moment'
import confirmacionPrecio from '~/services/retiros/confirmacion_precio';
import { mapGetters } from 'vuex'
export default {
    components: {
        modalPrecioIndividual : () => import('./partials/modalConfPrecioProducto')
    },
    data(){
        return {
            dataColumns: [
                { valor: 'cedis_nombre', titulo:"Cedis", class: 'text-general f-16' },
                { valor: 'proveedor_nombre', titulo:"Proveedor", class: 'text-general f-16' },
                { valor: 'pedido', titulo:"Pedido", class: 'text-general f-16' },
                { valor: 'fecha', titulo:"Entrega", class: 'text-general f-16' },
                { valor: 'delivery_nombre', titulo:"Delivery", class: 'text-general f-16' },
            ],
            loading: false,
            lista: [],
            selected: []
        }
    },

    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        masUnaSemana(){
            const haceUnaSemana = moment().subtract(7, 'days')
            return this.lista.filter(el => moment(el.entrega_fecha).isBefore(haceUnaSemana, 'day')).length
        },
        haceUnaSemana(){
            const haceUnaSemana = moment().subtract(7, 'days')
            return this.lista.filter(el => moment(el.entrega_fecha).isSameOrAfter(haceUnaSemana, 'day')).length
        },
        ayer(){
            const ayer = moment().subtract(1, 'days')
            return this.lista.filter(el => moment(el.entrega_fecha).isSame(ayer, 'day')).length
        },
        hoy(){
            const hoy = moment()
            return this.lista.filter(el => moment(el.entrega_fecha).isSame(hoy, 'day')).length
        }
    },
    watch: {
        id_moneda(val, oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_cedis(val, oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
    },
    mounted(){
        this.montar()
    },
    methods:{
        async montar(){
            try {
                if(this.id_moneda == null || this.id_cedis == null){
                    setTimeout(async() => {
                        await this.listar()
                    }, 1000);
                }else{
                    await this.listar()
                }
            } catch (error){
                console.log(error)
            }
        },
        onSelect(row){
            this.selected = row.map(el => el.id)
        },
        confirmaPrecio(){
            if(!this.selected.length) return this.notificacion('', 'Debe seleccionar al menos un item', 'error')
            this.$refs.modalConfirmarPrecioTodos.toggle();
        },
        onClick(row){
            console.log(row);
            this.$refs.modalConfirmarPrecioInd.toggle(row);
        },
        async listar(){
            try {
                this.loading = true
                const params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                }
                const { data } = await confirmacionPrecio.listar(params)
                this.lista = data;
            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading = false

            }
        },
        async confirmarMultiple(){
            try {
                this.loading = true
                const datos = {
                    ids: this.selected
                }
                const { data } = await confirmacionPrecio.checkMulti(datos)
                if(data.exito){
                    this.notificacion('', 'Productos confirmados correctamente', 'success')
                    this.$refs.modalConfirmarPrecioTodos.toggle();
                    this.selected = []
                    this.listar()
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false

            }
        }
    }
}
</script>
