import axios from 'axios'

const API_PREFIX = "estadisticas-dev-eli";

const ENDPOINTS = {
    informe: params => axios(`${API_PREFIX}/informe`, { params }),
    verMasAdmin: params => axios(`${API_PREFIX}/ver-mas-admin`, { params }),
    verMasProductos: params => axios(`${API_PREFIX}/ver-mas-productos`, { params })
};

export default ENDPOINTS
