<template>
    <section class="px-3">
        <div class="row mx-0 my-3 bg-white br-8 pt-3">
            <div class="col-12 text-general f-18 f-600">
                Administradores con mayor eliminación de productos
            </div>
            <div class="col">
                <div class="d-flex mx-0 my-2 mt-3">
                    <div v-for="(usuario,index) in usuariosEliminan.slice(0, 5)" :key="index" class="col-auto">
                        <el-tooltip class="item" effect="light" :content="usuario.usuario.nombre" placement="top-start">
                            <img :src="usuario.usuario.foto" class="card-productos obj-cover br-10 border" />
                        </el-tooltip>
                        <p class="text-general text-center f-500 my-2">
                            {{ usuario.total }}
                        </p>
                    </div>
                    <div class="col-auto px-0 text-general f-15 pt-4 f-600" @click="verMas()">
                        <div class="mt-2 cr-pointer">
                            Ver más
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div class="col-auto">
                        <p class="text-general my-2 f-14 f-500">
                            Productos eliminados <br /> en la última semana
                        </p>
                    </div>
                    <div class="col d-flex">
                        <div class="bg-fondo border text-center br-12 f-22 d-middle-center f-600 text-general" style="width: 99px;height: 50px;">
                            {{ eliminadosSemana }}
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-1">
                    <div class="col-auto">
                        <p class="text-general my-2 f-14 f-500">
                            Productos eliminados <br /> en el último mes
                        </p>
                    </div>
                    <div class="col d-flex">
                        <div class="bg-fondo border text-center br-12 f-22 d-middle-center f-600 text-general" style="width: 99px;height: 50px;">
                            {{ eliminadosMes }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12 px-0">
                <tabla-general :data="productos" :columnas="dataColumns" alto="calc(100vh - 459px)" sin-datos="No hay productos eliminados" @funcionEmit="productoEliminado">
                    <template slot="cabecera-izquierda">
                        <div class="col text-general f-600">
                            Productos eliminados del sistema
                        </div>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminacion-productos ref="modalEliminacionProductos" />
        <modal-producto-eliminado ref="modalProductoEliminado" />
    </section>
</template>

<script>
import ProductosEliminados from "~/services/configurar/admin/retiros/productosEliminados";
export default {
    components: {
        modalEliminacionProductos: () => import('./partials/modalEliminacionProductos'),
        modalProductoEliminado: () => import('./partials/modalProductoEliminado')
    },
    data(){
        return {
            url_image: 'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
            productos: [{}],
            dataColumns: [
                {valor: 'id_asignado' , titulo: 'ID', class: 'text-general f-16 f-600 text-center', ancho: 80},
                {valor: 'sku' , titulo: 'SKU', class: 'text-general f-16 f-600',modal:true},
                {valor: 'nombre' , titulo: 'Producto', class: 'text-general f-16 f-600'},
                {valor: 'presentacion' , titulo: 'Presentacion', class: 'text-general text-center f-16 f-600'},
                {valor: 'fecha_eliminado' , titulo: 'Fecha de eliminación', class: 'text-general text-center f-16 f-600'},
                {valor: 'responsable' , titulo: 'Responsable', class: 'text-general text-center f-16 f-600' }
            ],
            eliminadosSemana : 0,
            eliminadosMes : 0,
            usuariosEliminan : []
        }
    },
    mounted(){
        this.listarProductos()
    },
    methods: {
        verMas(){
            this.$refs.modalEliminacionProductos.toggle(this.usuariosEliminan);
        },
        productoEliminado(producto){
            this.$refs.modalProductoEliminado.toggle(producto);
        },
        async listarProductos(){
            try {
                const { data } = await ProductosEliminados.GetProductos()
                this.productos = data.productos
                this.eliminadosSemana = data.eliminadosSemana
                this.eliminadosMes = data.eliminadosMes
                this.usuariosEliminan = data.usuariosEliminan
                console.log(data)
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.card-productos{
    width: 80px;
    height: 80px;
}
</style>
