<template>
    <section>
        <div class="row mx-0">
            <div class="col-12 my-3 px-4">
                <p class="text-general f-18 f-600">
                    {{ getRoute ? 'Estadísticas de descarte en pedidos de abastecimiento' : 'Estadísticas de devolución en pedidos de abastecimiento' }}
                </p>
            </div>
            <div class="col-12 my-3">
                <div class="row mx-0 align-items-center">
                    <div class="col-auto text-general f-500">
                        Rango de análisis
                    </div>
                    <div class="col-xl-2 col-lg-4 pl-0 pr-2">
                        <el-select v-model="rango" placeholder="Seleccionar" size="small" class="w-100">
                            <el-option
                            v-for="item in rangos"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                    <div class="col-xl-2 col-lg-4 px-0 my-1">
                        <div class="date-picker-edit">
                            <el-date-picker
                            v-model="fecha"
                            class="w-100"
                            size="small"
                            format="dd-MM-yyyy"
                            value-format="yyyy-MM-dd"
                            :disabled="disable_fecha"
                            popper-class="date-picker-edit-popper"
                            type="daterange"
                            unlink-panels
                            start-placeholder="Fecha inicio"
                            end-placeholder="Fecha fin"
                            />
                        </div>
                    </div>
                    <div class="col" />
                    <div class="col-auto">
                        <span class="text-general f-500 my-1">
                            {{ getRoute ? 'Motivos de descarte' : 'Motivos de devolución' }}
                        </span>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-3">
                        <el-select v-model="motivos_select" placeholder="Seleccionar" size="small" class="mx-1 w-100" multiple collapse-tags>
                            <el-option
                            v-for="item in motivos_lista"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                    <div class="col-auto d-middle">
                        <i class="icon-trash-empty f-18 text-general2 mx-2 cr-pointer" @click="motivos_select = []" />
                        <div class="col-auto px-0">
                            <div class="btn-general bg-general cr-pointer br-8 text-white px-3" @click="informes">
                                Aplicar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 py-2" />
        <div class="row mx-0 bg-white br-8 py-3 mx-3">
            <div class="col-auto pl-4 f-18 text-general f-600">
                Índice de descarte
            </div>
            <div class="col text-center">
                <p class="text-general f-30 f-600">{{ separadorNumero(indices.totalPedidos) }}</p>
                <p class="text-center text-general">
                    <i class="icon-comision text-general mr-2 f-18" />
                    Valor de los pedidos
                </p>
            </div>
            <div class="col text-center">
                <p class="text-general-red f-30 f-600">{{ agregarSeparadoresNumero(indices.porcentaje,2) }} %</p>
                <p class="text-center text-general">
                    <i class="icon-comision text-general mr-2 f-18" />
                    Descarte
                </p>
            </div>
            <div class="col text-center">
                <p class="text-general f-30 f-600">{{ separadorNumero(indices.totalDescartes) }}</p>
                <p class="text-center text-general">
                    <i class="icon-comision text-general mr-2 f-18" />
                    Equivalencia
                </p>
            </div>
        </div>
        <div class="row mx-0 py-2" />
        <div class="row mx-3 bg-white br-8 py-4">
            <div class="col pl-4 text-general f-18 f-600">
                {{ getRoute ? 'Comparación de descarte entre cedis' : 'Comparación de devolución entre cedis' }}
            </div>
            <div class="col d-middle">
                <span class="text-general f-18 f-600">Ordenar Por:</span>
                <div class="row mx-0">
                    <div class="col-auto d-middle" :class="ordenar_arriba == 1 ? 'text-general' : 'text-gris'">
                        <input type="radio" class="option-input black radio" name="filtrar" :checked="ordenar_arriba == 1" @click="ordenar_arriba = 1" />
                        Dinero
                    </div>
                    <div class="col-auto d-middle" :class="ordenar_arriba == 2 ? 'text-general' : 'text-gris'">
                        <input type="radio" class="option-input black radio" name="filtrar" :checked="ordenar_arriba == 2" @click="ordenar_arriba = 2" />
                        Cantidad
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row mx-0 pt-3">
                    <div v-for="(cedi,c) in sortCedis" :key="c" class="card-comparacion py-1 px-2 d-middle br-12 my-2 mx-1">
                        <img :src="cedi.foto" width="110" height="100%" class="obj-fit br-12 mr-2" />
                        <div class="col d-flex flex-column tres-puntos px-0 text-general">
                            <div class="row mx-0">
                                <p class="col my-2 nombre-comparacion px-0">
                                    {{ cedi.nombre }}
                                </p>
                            </div>
                            <div class="row mx-0">
                                <div class="col px-0 tres-puntos">
                                    <p class="tres-puntos text-muted2">
                                        {{ separadorNumero(cedi.dinero) }}
                                    </p>
                                </div>
                            </div>
                            <div class="row mx-0">
                                <p class="col px-0 text-muted2 d-middle">
                                    {{ agregarSeparadoresNumero(cedi.cantidad) }} Productos
                                </p>
                                <div class="bg-general3 text-white f-600 f-16 br-20 py-1 px-3">
                                    {{ agregarSeparadoresNumero(ordenar_arriba == 1 ? cedi.porcentaje_dinero : cedi.porcentaje_cantidad,1) }}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 py-2" />
        <div class="row mx-0">
            <div class="col bg-white ml-3 br-8 py-3">
                <p class="text-general f-18 f-600 text-center">
                    {{ getRoute ? 'Motivos de descarte por dinero' : 'Motivos de devolución por dinero' }}
                </p>
                <echart :options="optionsDinero" />
                <el-popover
                v-model="visibleDineroMotivos"
                popper-class="popoverEdit"
                placement="top"
                trigger="manual"
                >
                    <div class="row justify-center mx-0 position-relative">
                        <i class="icon-cancel text-general cr-pointer position-absolute f-18" style="right:0px;top:-3px;" @click="visibleDineroMotivos = !visibleDineroMotivos" />
                        Motivos
                    </div>
                    <div v-for="(activo, r) in motivos_x_dinero" :key="r" class="row mx-2 justify-content-center py-2">
                        <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${activo.color}`" />
                        <div class="col">
                            {{ activo.name }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(activo.percent,1) }}%
                        </div>
                        <div class="col-auto">
                            {{ separadorNumero(activo.value) }}
                        </div>
                    </div>
                    <div v-if="motivos_x_dinero.length" slot="reference" class="row justify-center text-g-light f-15 cr-pointer" @click="visibleDineroMotivos = !visibleDineroMotivos">
                        Ver más <i class="icon-angle-up text-general f-15 cr-pointer" />
                    </div>
                </el-popover>
            </div>
            <div class="col bg-white mr-3 br-8 py-3 ml-3">
                <p class="text-general f-18 f-600 text-center">
                    {{ getRoute ? 'Motivos de descarte por cantidad' : ' Motivos de devolución por cantidad' }}
                </p>
                <echart :options="optionsCantidades" />
                <el-popover
                v-model="visibleCantidadMotivos"
                popper-class="popoverEdit"
                placement="top"
                trigger="manual"
                >
                    <div class="row justify-center mx-0 position-relative">
                        <i class="icon-cancel text-general cr-pointer position-absolute f-18" style="right:0px;top:-3px;" @click="visibleCantidadMotivos = !visibleCantidadMotivos" />
                        Motivos
                    </div>
                    <div v-for="(activo, r) in motivos_x_cantidad" :key="r" class="row mx-2 justify-content-center py-2">
                        <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${activo.color}`" />
                        <div class="col">
                            {{ activo.name }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(activo.percent,1) }}%
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(activo.value) }}
                        </div>
                    </div>
                    <div v-if="motivos_x_cantidad.length" slot="reference" class="row justify-center text-g-light f-15 cr-pointer" @click="visibleCantidadMotivos = !visibleCantidadMotivos">
                        Ver más <i class="icon-angle-up text-general f-15 cr-pointer" />
                    </div>
                </el-popover>
            </div>
        </div>
        <div class="row mx-0 py-2" />
        <div class="row mx-3 bg-white br-8 py-3">
            <p class="col-12 mb-3 px-4 text-general f-18 f-600">
                {{ getRoute ? 'Responsables con mayor cantidad de productos descartados' : 'Responsables con mayor cantidad de productos devueltos' }}
            </p>
            <div class="col pr-0 pl-3">
                <div class="row mx-0">
                    <div v-for="(user,u) in admin" :key="u" class="col-auto mb-1">
                        <el-tooltip placement="bottom" :content="user.nombre" effect="light">
                            <div class="card-producto">
                                <img :src="user.foto" width="100%" height="100%" alt="" />
                            </div>
                        </el-tooltip>
                        <p class="text-center mt-1 text-general"> {{ agregarSeparadoresNumero(user.cantidad) }} </p>
                        <p class="text-general f-16 text-center"> {{ separadorNumero(user.dinero) }} </p>
                    </div>
                </div>
            </div>
            <div v-if="admin_mas > 0" class="col-auto">
                <div class="bg-general3 cr-pointer d-flex flex-column justify-content-around text-white br-12 p-3 text-center mx-auto" style="width:100px;height:100px;" @click="abrirOperarios()">
                    <p class="text-center">
                        Ver más
                    </p>
                    <i class="icon-angle-right f-17" />
                </div>
                <p class="text-general f-17 mt-1">
                    {{ agregarSeparadoresNumero(admin_mas) }} Operarios
                </p>
            </div>
        </div>
        <div class="row mx-0 py-2" />
        <div class="row mx-3 bg-white br-8 py-3">
            <p class="col-12 mb-3 px-4 text-general f-18 f-600">
                {{ getRoute ? 'Productos con mayor descarte' : 'Productos con mayor cantidad de devoluciones' }}
            </p>
            <div class="col pr-0 pl-3">
                <div class="row mx-0">
                    <div v-for="(producto,p) in productos" :key="p" class="col-auto mb-1">
                        <el-tooltip placement="bottom" :content="producto.nombre" effect="light">
                            <div class="card-producto">
                                <img :src="producto.foto" width="100%" height="100%" alt="" />
                            </div>
                        </el-tooltip>
                        <p class="text-general f-16 text-center"> {{ `${agregarSeparadoresNumero(producto.cantidades)} ${producto.unidad}` }}</p>
                    </div>
                </div>
            </div>
            <div v-if="productos_mas > 0" class="col-auto">
                <div class="bg-general3 cr-pointer d-flex flex-column justify-content-around text-white br-12 p-3 text-center mx-auto" style="width:100px;height:100px;" @click="abrirEstadisticas()">
                    <p class="text-center">
                        Ver más
                    </p>
                    <i class="icon-angle-right f-17" />
                </div>
                <p class="text-general  f-17 mt-1">
                    {{ agregarSeparadoresNumero(productos_mas) }} Productos
                </p>
            </div>
        </div>
        <div class="row mx-0 py-2" />
        <div class="row mx-3 bg-white br-t-8 py-3">
            <div class="col pl-4 text-general f-18 f-600">
                {{ getRoute ? 'Categorías con mayor descarte' : 'Categorías con más devoluciones' }}
            </div>
            <div class="col d-middle">
                <span class="text-general f-18 f-600">Ordenar Por:</span>
                <div class="row mx-0">
                    <div class="col-auto d-middle" :class="ordenar_abajo == 1 ? 'text-general' : 'text-gris'">
                        <input type="radio" class="option-input opt-general radio" name="filtrar1" :checked="ordenar_abajo == 1" @click="ordenar_abajo = 1" />
                        Dinero
                    </div>
                    <div class="col-auto d-middle" :class="ordenar_abajo == 2 ? 'text-general' : 'text-gris'">
                        <input type="radio" class="option-input opt-general radio" name="filtrar1" :checked="ordenar_abajo == 2" @click="ordenar_abajo = 2" />
                        Cantidad
                    </div>
                </div>
            </div>
        </div>
        <div v-for="(categoria,c) in sortCategorias" :key="c" class="row mx-3 bg-white py-3" :class="sortCategorias.length - 1 == c ? 'br-b-8' : ''">
            <div class="col pl-5">
                <p class="text-general">
                    {{ categoria.nombre }}
                </p>
                <div class="row mx-0 mt-1">
                    <div class="col-2 f-16 px-0">
                        {{ agregarSeparadoresNumero(categoria.cantidad) }} Solicitudes
                    </div>
                    <div class="col-2 f-16">
                        {{ separadorNumero(categoria.dinero) }}
                    </div>
                </div>
                <div class="row mx-0" style="height:15px;">
                    <div class="bg-gris br-12 h-100" :style="`width:${agregarSeparadoresNumero(ordenar_abajo == 1 ? categoria.porcentaje_max_dinero : categoria.porcentaje_max_cantidad)}%;`" />
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-estadisticas ref="modalEstadisticas" :titulo="getRoute?'Productos con mayor descarte' : 'Productos con mayor cantidad de devoluciones'" />
        <modal-operarios ref="modalOperarios" :titulo="getRoute?'Responsables con mayor cantidad de productos eliminados' : ' Responsables que más veces han devuelto productos de un pedido'" />
    </section>
</template>

<script>
import Vue from 'vue'
import echarts from 'echarts'
import Pedidos from '~/services/pedidos/pedidos-tienda'
import Estadisticas from '~/services/estadisticas_devoluciones_eliminaciones'
import moment from 'moment'
import { mapGetters } from 'vuex'
// Vue.component('echarts',echarts);
let color = [ '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88', '#9FDDF8', '#F171D8', '#7797F1', '#DC7279', '#B78471', '#76A0B8', '#87DC72', ];

export default {
    components: {
        modalEstadisticas: () => import('./partials/modalEstadisticas'),
        modalOperarios: () => import('./partials/modalOperarios')
    },
    data(){
        let self = this
        return {
            motivos_lista:[],
            motivos_select:[],
            ordenar_arriba: 1,
            ordenar_abajo: 1,
            motivos_x_dinero: [],
            motivos_x_cantidad: [],
            activos: [
                {
                    color: 'red'
                },
                {
                    color: 'green'
                },
                {
                    color: 'orange'
                },
                {
                    color: 'green'
                }
            ],
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            disable_fecha: true,
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            optionsDinero :{
                color: color,
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.separadorNumero(params.value.dinero)} (${params.percent}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','dinero'],
                    source:[],
                },
                series: [
                    {
                        name: 'Motivos',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            optionsCantidades :{
                // title: {
                //     text: 'Motivos',
                //     // subtext: `${this.agregarSeparadoresNumero(this.porcentajes.rechazos)}%`,
                //     textStyle: {
                //         color: '#000000',
                //         fontSize: 20,
                //         fontWeight: 'normal'
                //     },
                //     subtextStyle: {
                //         fontSize: 20,
                //         color: '#0d4dff',
                //         fontWeight: 'bold'
                //     },
                //     left: "center",
                //     top: 5,
                // },
                color: color,
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.agregarSeparadoresNumero(params.value.cantidad)} (${params.percent}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','cantidad'],
                    source:[],
                },
                series: [
                    {
                        name: 'Motivos',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            rango: 3,
            motivos: [],
            categorias: [],
            admin: [],
            admin_mas: 0,
            cedis: [],
            productos: [],
            productos_mas: 0,
            visibleCantidadMotivos: false,
            visibleDineroMotivos: false,
            cargandoInforme: true,
            primeraVez: true,
            indices: {
                totalPedidos: 0,
                totalDescartes: 0,
                porcentaje: 0,
            }
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            /* cedis:'cedis/cedis', */
            id_moneda:'cedis/id_moneda',
        }),
        getRoute(){
            return this.$route.name == 'admin.retiros.estadisticas-eliminacion'
        },
        sortCedis(){
            let cedis = []
            cedis = _.orderBy(this.cedis, this.ordenar_arriba == 1 ? 'dinero': 'cantidad','desc')

            return cedis
        },
        sortCategorias(){
            let categorias = []
            categorias = _.orderBy(this.categorias, this.ordenar_abajo == 1 ? 'dinero': 'cantidad','desc')

            return categorias
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            if(val == null) return
            this.cargando()
            this.montar()
        },
        id_cedis(val,oldVal){
            if(oldVal == null) return
            if(val == null) return
            this.cargando()
            this.montar()
        },
        '$route.name':{
            handler(){
                this.motivos_lista = []
                this.motivos_select = []
                this.select_motivos()
                this.montar()
            }
        }
    },
    mounted(){
        this.select_motivos()
        this.montar()
    },
    methods: {
        cargando(){
            this.cargandoInforme = true
        },
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.informe()
                    }, 1000);
                }else{
                    await this.informe()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        abrirEstadisticas(){
            let params = {
                tipo: this.getRoute ? 2 : 1,
                fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                motivos: this.motivos_select,
                id_moneda: this.id_moneda,
                id_cedis: this.id_cedis,
            }
            this.$refs.modalEstadisticas.toggle(params);
        },
        abrirOperarios(){
            let params = {
                tipo: this.getRoute ? 2 : 1,
                fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                motivos: this.motivos_select,
                id_moneda: this.id_moneda,
                id_cedis: this.id_cedis,
            }
            this.$refs.modalOperarios.toggle(params);
        },
        async informe(){
            try {
                let params = {
                    tipo: this.getRoute ? 2 : 1,
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    motivos: this.motivos_select,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                    ordenar_arriba: this.ordenar_arriba,
                    ordenar_abajo: this.ordenar_abajo,
                }
                const {data} = await Estadisticas.informe(params)

                this.indices = data.indices

                this.optionsDinero.dataset.source = data.motivos
                this.optionsCantidades.dataset.source = data.motivos
                this.categorias = data.categorias
                this.admin = data.admin
                this.admin_mas = data.admin_mas
                this.cedis = data.cedis
                this.productos = data.productos
                this.productos_mas = data.productos_mas


                let motivos_x_dinero = []
                let motivos_x_cantidad = []

                let motivos = data.motivos
                for (var i = 0; i < motivos.length; i++){
                    motivos_x_dinero.push({
                        value: motivos[i].dinero,
                        name: motivos[i].nombre,
                        percent: motivos[i].porcentaje_dinero,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                    motivos_x_cantidad.push({
                        value: motivos[i].cantidad,
                        name: motivos[i].nombre,
                        percent: motivos[i].porcentaje_cantidad,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },

                    });
                }
                this.motivos_x_dinero = _.orderBy(motivos_x_dinero,'value','desc')
                this.motivos_x_cantidad = _.orderBy(motivos_x_cantidad,'value','desc')


            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargandoInforme = true
            }
        },
        async informes(){
            try {
                this.tipo = 1
                this.cargando()

                await this.informe()

            } catch (error){
                console.log(error)
            }
        },
        async select_motivos(){
            try {
                let params = {
                    tipo: this.getRoute ? 11 : 12
                }
                const {data} = await Pedidos.select_motivos(params)
                this.motivos_lista = data.motivos

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{ border-radius: 12px !important; }
.card-comparacion {
    border: 1px solid #EBEBEB;
    width: 380px;
}
.nombre-comparacion{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
.card-producto{
    width: 100px;
    height: 100px;

    border: 1px solid #DBDBDB;
    border-radius: 12px;
    img{
        border-radius: 12px;
        object-fit: cover;
    }
}
</style>
